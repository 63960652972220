import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5243c7ab"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=5243c7ab&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/home/forge/farescraper.com/components/AppHeader.vue').default,HomeHero: require('/home/forge/farescraper.com/components/HomeHero.vue').default,GoogleAdSense: require('/home/forge/farescraper.com/components/GoogleAdSense.vue').default,HomeSteps: require('/home/forge/farescraper.com/components/HomeSteps.vue').default,AppFooter: require('/home/forge/farescraper.com/components/AppFooter.vue').default,AppMobileFooter: require('/home/forge/farescraper.com/components/AppMobileFooter.vue').default,ModalNewsletter: require('/home/forge/farescraper.com/components/ModalNewsletter.vue').default,DebugBar: require('/home/forge/farescraper.com/components/DebugBar.vue').default})
