
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { parse } from 'node-html-parser'
import getHead from '~/helpers/getHead'
import parseBlogPost from '~/helpers/parseBlogPost'
import loadScript from '@/helpers/loadScript'
import GoogleAdSense from '@/components/GoogleAdSense.vue';
import EzoicAds from '@/components/EzoicAds.vue'

const destinations = [{ label: 'San Francisco', code: 'SFO' }, { label: 'Paris', code: 'PAR' }, { label: 'Sydney', code: 'SYD' }]

export default {
  beforeRouteLeave (to, from, next) {
    this.$store.commit('setTranslationRedirects', {})
    next()
  },
  async asyncData ({ $axios, $config, params, payload, store, error }) {
    if (payload) return payload

    const { siteType, siteName } = store.state

    const blogUrl = $config.BLOG_URL
    const api = `${blogUrl}/wp-json/wp/v2`

    let post

    if (params.slug.startsWith('preview:')) {
      const postId = params.slug.replace('preview:', '')
      post = await $axios.$get(`${api}/posts/${postId}?_embed`)
      if (!post) return error({ statusCode: 404, message: 'Post not found' })
      post = parseBlogPost(post, blogUrl)
    } else {
      const posts = await $axios.$get(`${api}/posts?slug=${params.slug}&_embed`)
      if (!posts.length) return error({ statusCode: 404, message: 'Post not found' })
      post = parseBlogPost(posts[0], blogUrl)
    }

    const response = await $axios.get(`${blogUrl}/wp-json/wp/v2/posts`, {
      params: {
        _embed: true,
        per_page: 3,
        page: 1,
        categories: $config.BLOG.LOCALE[params.category.toUpperCase()],
        tags: $config.BLOG.TAGS[siteType.toUpperCase()]
      }
    })

    const featuredPosts = response.data
      .map(item => parseBlogPost(item, blogUrl))
      .filter(item => item.slug !== params.slug)
      .slice(0, 2)

    const html = post.content.rendered

    const root = parse(html)

    const headings = root.querySelectorAll('h1, h2, h3')
    if (headings.length) {
      headings[Math.floor(headings.length / 2)].insertAdjacentHTML(
        'beforebegin',
        '<!--<div class="bg-abbey text-white flex items-center justify-center h-[300px]">AD</div>-->'
      )
    }

    const links = root.querySelectorAll('a')
    links.forEach(link => link.setAttribute('target', '_blank'))

    const content = root.toString().replace('WEBSITE_BRAND', `<a href="/">${siteName}</a>`)

    store.commit('setTranslationRedirects', post.translations)

    return { post, featuredPosts, content }
  },
  data () {
    return {
      post: null,
      featuredPosts: [],
      content: null,
      destinations
    }
  },
  head () {
    if (!this.post.yoast_head_json) {
      return this.$nuxt.error({ statusCode: 500, message: 'Please update Yoast SEO to version 16.7' })
    }

    const blogUrl = this.$config.BLOG_URL
    const { site } = this.$store.state

    const head = getHead({
      root: `https://${site}`,
      url: this.post.link,
      title: this.post.yoast_head_json.title,
      description: this.post.yoast_head_json.description,
      image: this.post.image,
      twitterHandle: null
    })

    head.meta = head.meta.concat([
      { hid: 'article:published_time', property: 'article:published_time', content: this.post.date },
      { hid: 'article:modified_time', property: 'article:modified_time', content: this.post.modified }
    ])

    // TO-DO: Add JSON+LD data for SEO
    /*
    const regex = /https:\/\/blog.farescraper.com\/(?!wp-content)/gm
    const subst = blogUrl
    const result = JSON.stringify(this.post.yoast_json_ld[0]).replace(regex, subst)
    head.script.push({
      type: 'application/ld+json',
      json: JSON.parse(result)
    })
    */

    return head
  },
  computed: {
    author () {
      return this.post._embedded.author[0]
    },
    siteType () {
      return this.$store.state.siteType
    },
    adsenseId() {
      return this.$config.ADSENSE_ID ?? null;
    },
    showEzoic () {
      return this.$store.state.siteConfig.ezoicActive
    },
    routeName () {
      return this.$route.name
    }
  },
  onMounted () {
    const { siteConfig } = this.$store.state
    if (siteConfig.shareTrips) {
      window.ShareTripsParamData = { type: 'flight' }
      loadScript(`https://chat.sharetrips.com/embed/main.js?site=${siteConfig.shareTrips}`, true)
    }
  }
}
